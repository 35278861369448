import { YEAR_MONTH_DAY_FORMAT } from "@slrc/shared/utils";
import { format } from "date-fns";
export const UK_PLAN4_INTEREST_RATES = [
    {
        from: format(new Date("1998-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("1999-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 3.5,
        maxVariable: 0
    },
    {
        from: format(new Date("1999-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2000-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 2.1,
        maxVariable: 0
    },
    {
        from: format(new Date("2000-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2001-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 2.6,
        maxVariable: 0
    },
    {
        from: format(new Date("2001-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2002-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 2.3,
        maxVariable: 0
    },
    {
        from: format(new Date("2002-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2003-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.3,
        maxVariable: 0
    },
    {
        from: format(new Date("2003-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2004-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 3.1,
        maxVariable: 0
    },
    {
        from: format(new Date("2004-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2005-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 2.6,
        maxVariable: 0
    },
    {
        from: format(new Date("2005-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2006-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 3.2,
        maxVariable: 0
    },
    {
        from: format(new Date("2006-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2007-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 2.4,
        maxVariable: 0
    },
    {
        from: format(new Date("2007-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2008-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 4.8,
        maxVariable: 0
    },
    {
        from: format(new Date("2008-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2008-12-04"), YEAR_MONTH_DAY_FORMAT),
        base: 3.8,
        maxVariable: 0
    },
    {
        from: format(new Date("2008-12-05"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2009-01-08"), YEAR_MONTH_DAY_FORMAT),
        base: 3.0,
        maxVariable: 0
    },
    {
        from: format(new Date("2009-01-09"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2009-02-05"), YEAR_MONTH_DAY_FORMAT),
        base: 2.5,
        maxVariable: 0
    },
    {
        from: format(new Date("2009-02-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2009-03-05"), YEAR_MONTH_DAY_FORMAT),
        base: 2.0,
        maxVariable: 0
    },
    {
        from: format(new Date("2009-03-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2009-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.5,
        maxVariable: 0
    },
    {
        from: format(new Date("2009-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2010-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 0,
        maxVariable: 0
    },
    {
        from: format(new Date("2010-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2011-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.5,
        maxVariable: 0
    },
    {
        from: format(new Date("2011-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2012-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.5,
        maxVariable: 0
    },
    {
        from: format(new Date("2012-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2013-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.5,
        maxVariable: 0
    },
    {
        from: format(new Date("2013-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2014-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.5,
        maxVariable: 0
    },
    {
        from: format(new Date("2014-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2015-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.5,
        maxVariable: 0
    },
    {
        from: format(new Date("2015-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2016-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 0.9,
        maxVariable: 0
    },
    {
        from: format(new Date("2016-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2017-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.25,
        maxVariable: 0
    },
    {
        from: format(new Date("2017-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2017-11-30"), YEAR_MONTH_DAY_FORMAT),
        base: 1.25,
        maxVariable: 0
    },
    {
        from: format(new Date("2017-12-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2018-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.5,
        maxVariable: 0
    },
    {
        from: format(new Date("2018-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2020-04-06"), YEAR_MONTH_DAY_FORMAT),
        base: 1.75,
        maxVariable: 0
    },
    {
        from: format(new Date("2020-04-07"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2021-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.1,
        maxVariable: 0
    },
    {
        from: format(new Date("2020-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2021-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.1,
        maxVariable: 0
    },
    {
        from: format(new Date("2021-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2022-01-12"), YEAR_MONTH_DAY_FORMAT),
        base: 1.1,
        maxVariable: 0
    },
    {
        from: format(new Date("2022-01-13"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2022-03-02"), YEAR_MONTH_DAY_FORMAT),
        base: 1.25,
        maxVariable: 0
    },
    {
        from: format(new Date("2022-03-03"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2022-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.5,
        maxVariable: 0
    },
    {
        from: format(new Date("2022-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2022-10-19"), YEAR_MONTH_DAY_FORMAT),
        base: 2.75,
        maxVariable: 0
    },
    {
        from: format(new Date("2022-10-20"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2022-12-01"), YEAR_MONTH_DAY_FORMAT),
        base: 3.25,
        maxVariable: 0
    },
    {
        from: format(new Date("2022-12-02"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2023-01-11"), YEAR_MONTH_DAY_FORMAT),
        base: 4,
        maxVariable: 0
    },
    {
        from: format(new Date("2023-01-12"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2023-03-02"), YEAR_MONTH_DAY_FORMAT),
        base: 4.5,
        maxVariable: 0
    },
    {
        from: format(new Date("2023-03-03"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2023-04-20"), YEAR_MONTH_DAY_FORMAT),
        base: 5,
        maxVariable: 0
    },
    {
        from: format(new Date("2023-04-21"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2023-06-08"), YEAR_MONTH_DAY_FORMAT),
        base: 5.25,
        maxVariable: 0
    },
    {
        from: format(new Date("2023-06-09"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2023-07-20"), YEAR_MONTH_DAY_FORMAT),
        base: 5.5,
        maxVariable: 0
    },
    {
        from: format(new Date("2023-07-21"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2023-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 6,
        maxVariable: 0
    },
    {
        from: format(new Date("2023-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-08-29"), YEAR_MONTH_DAY_FORMAT),
        base: 6.25,
        maxVariable: 0
    },
    {
        from: format(new Date("2024-08-30"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 6,
        maxVariable: 0
    },
    {
        from: format(new Date("2024-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2025-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 4.3,
        maxVariable: 0
    }
];
