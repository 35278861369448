var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ebe5f69b7083ad5587529949574d8c9b181a206f"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  SLRC_WEB_BUILD_ID,
  NEXT_PUBLIC_SLRC_WEB_APP_ENV,
  SLRC_WEB_SENTRY_TRACES_SAMPLE_RATE,
} from '@slrc/env';

Sentry.init({
  dsn: 'https://df1a087d84604d558f4c70136ebaf31a@o1166383.ingest.sentry.io/6256835',
  environment: NEXT_PUBLIC_SLRC_WEB_APP_ENV,
  release: SLRC_WEB_BUILD_ID,
  tracesSampler: (samplingContext) => {
    // Don't send any events for local development
    if (samplingContext.location.hostname === 'localhost') {
      return 0;
    }
    return Number(SLRC_WEB_SENTRY_TRACES_SAMPLE_RATE) || 0;
  },
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
  onFatalError: (e) => {
    console.log('Error');
    console.log(e.message);
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
