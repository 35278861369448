import { YEAR_MONTH_DAY_FORMAT } from "@slrc/shared/utils";
import { format } from "date-fns";
export const UK_PLAN2_REPAYMENT_THRESHOLDS = [
    {
        from: format(new Date("2016-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2018-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 21000,
        max: 41000,
        repaymentRate: 9
    },
    {
        from: format(new Date("2018-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2019-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 25000,
        max: 45000,
        repaymentRate: 9
    },
    {
        from: format(new Date("2019-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2020-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 25725,
        max: 46305,
        repaymentRate: 9
    },
    {
        from: format(new Date("2020-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2021-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 26575,
        max: 47835,
        repaymentRate: 9
    },
    {
        from: format(new Date("2021-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2025-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 27295,
        max: 49130,
        repaymentRate: 9
    },
    {
        from: format(new Date("2025-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2026-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 28470,
        max: 51245,
        repaymentRate: 9
    }
];
