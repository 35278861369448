import { YEAR_MONTH_DAY_FORMAT } from "@slrc/shared/utils";
import { format } from "date-fns";
export const UK_PLAN5_INTEREST_RATES = [
    {
        from: format(new Date("2023-08-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 4.1,
        maxVariable: 0
    },
    {
        from: format(new Date("2023-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-11-30"), YEAR_MONTH_DAY_FORMAT),
        base: 7.3,
        maxVariable: 0
    },
    {
        from: format(new Date("2023-12-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2023-12-31"), YEAR_MONTH_DAY_FORMAT),
        base: 7.5,
        maxVariable: 0
    },
    {
        from: format(new Date("2024-01-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-02-29"), YEAR_MONTH_DAY_FORMAT),
        base: 7.6,
        maxVariable: 0
    },
    {
        from: format(new Date("2024-03-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-03-31"), YEAR_MONTH_DAY_FORMAT),
        base: 7.7,
        maxVariable: 0
    },
    {
        from: format(new Date("2024-04-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-05-31"), YEAR_MONTH_DAY_FORMAT),
        base: 7.8,
        maxVariable: 0
    },
    {
        from: format(new Date("2024-06-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-07-31"), YEAR_MONTH_DAY_FORMAT),
        base: 7.9,
        maxVariable: 0
    },
    {
        from: format(new Date("2024-08-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 8,
        maxVariable: 0
    },
    {
        from: format(new Date("2024-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2025-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 4.3,
        maxVariable: 0
    }
];
