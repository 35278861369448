import { YEAR_MONTH_DAY_FORMAT } from "@slrc/shared/utils";
import { format } from "date-fns";
export const UK_PLAN4_REPAYMENT_THRESHOLDS = [
    {
        from: format(new Date("2000-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2005-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 10000,
        repaymentRate: 9
    },
    {
        from: format(new Date("2005-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2012-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 15000,
        repaymentRate: 9
    },
    {
        from: format(new Date("2012-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2013-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 15795,
        repaymentRate: 9
    },
    {
        from: format(new Date("2013-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2014-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 16365,
        repaymentRate: 9
    },
    {
        from: format(new Date("2014-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2015-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 16910,
        repaymentRate: 9
    },
    {
        from: format(new Date("2015-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2016-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 17335,
        repaymentRate: 9
    },
    {
        from: format(new Date("2016-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2017-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 17495,
        repaymentRate: 9
    },
    {
        from: format(new Date("2017-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2018-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 17775,
        repaymentRate: 9
    },
    {
        from: format(new Date("2018-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2019-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 18330,
        repaymentRate: 9
    },
    {
        from: format(new Date("2019-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2020-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 18935,
        repaymentRate: 9
    },
    {
        from: format(new Date("2020-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2021-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 19390,
        repaymentRate: 9
    },
    {
        from: format(new Date("2021-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2022-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 25000,
        repaymentRate: 9
    },
    {
        from: format(new Date("2022-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2023-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 25375,
        repaymentRate: 9
    },
    {
        from: format(new Date("2023-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 27660,
        repaymentRate: 9
    },
    {
        from: format(new Date("2024-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2025-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 31395,
        repaymentRate: 9
    },
    {
        from: format(new Date("2024-04-06"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2025-04-05"), YEAR_MONTH_DAY_FORMAT),
        min: 32745,
        repaymentRate: 9
    }
];
