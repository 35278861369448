import { YEAR_MONTH_DAY_FORMAT } from "@slrc/shared/utils";
import { format } from "date-fns";
export const UK_PLAN2_INTEREST_RATES = [
    {
        from: format(new Date("2012-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2013-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 3.6,
        maxVariable: 3
    },
    {
        from: format(new Date("2013-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2014-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 3.3,
        maxVariable: 3
    },
    {
        from: format(new Date("2014-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2015-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 2.5,
        maxVariable: 3
    },
    {
        from: format(new Date("2015-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2016-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 0.9,
        maxVariable: 3
    },
    {
        from: format(new Date("2016-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2017-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.6,
        maxVariable: 3
    },
    {
        from: format(new Date("2017-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2018-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 3.1,
        maxVariable: 3
    },
    {
        from: format(new Date("2018-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2019-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 3.3,
        maxVariable: 3
    },
    {
        from: format(new Date("2019-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2020-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 2.4,
        maxVariable: 3
    },
    {
        from: format(new Date("2020-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2021-06-30"), YEAR_MONTH_DAY_FORMAT),
        base: 2.6,
        maxVariable: 3
    },
    {
        from: format(new Date("2021-07-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2021-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 2.3,
        maxVariable: 3
    },
    {
        from: format(new Date("2021-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2021-09-30"), YEAR_MONTH_DAY_FORMAT),
        base: 1.2,
        maxVariable: 3
    },
    {
        from: format(new Date("2021-10-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2021-12-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.1,
        maxVariable: 3
    },
    {
        from: format(new Date("2022-01-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2022-02-28"), YEAR_MONTH_DAY_FORMAT),
        base: 1.4,
        maxVariable: 3
    },
    {
        from: format(new Date("2022-03-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2022-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 1.5,
        maxVariable: 3
    },
    {
        from: format(new Date("2022-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2022-11-30"), YEAR_MONTH_DAY_FORMAT),
        base: 7.3,
        maxVariable: 0
    },
    {
        from: format(new Date("2022-12-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2023-02-28"), YEAR_MONTH_DAY_FORMAT),
        base: 7.5,
        maxVariable: 0
    },
    {
        from: format(new Date("2023-03-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2023-05-31"), YEAR_MONTH_DAY_FORMAT),
        base: 7.9,
        maxVariable: 0
    },
    {
        from: format(new Date("2023-06-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2023-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 7.1,
        maxVariable: 0
    },
    {
        from: format(new Date("2023-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2023-11-30"), YEAR_MONTH_DAY_FORMAT),
        base: 7.3,
        maxVariable: 0
    },
    {
        from: format(new Date("2023-12-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-12-31"), YEAR_MONTH_DAY_FORMAT),
        base: 7.3,
        maxVariable: 0
    },
    {
        from: format(new Date("2024-01-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 7.3,
        maxVariable: 0
    },
    {
        from: format(new Date("2023-12-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2023-12-31"), YEAR_MONTH_DAY_FORMAT),
        base: 7.5,
        maxVariable: 3
    },
    {
        from: format(new Date("2024-01-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-02-29"), YEAR_MONTH_DAY_FORMAT),
        base: 7.6,
        maxVariable: 0
    },
    {
        from: format(new Date("2024-03-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-03-31"), YEAR_MONTH_DAY_FORMAT),
        base: 7.7,
        maxVariable: 0
    },
    {
        from: format(new Date("2024-04-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-05-31"), YEAR_MONTH_DAY_FORMAT),
        base: 7.8,
        maxVariable: 0
    },
    {
        from: format(new Date("2024-06-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-07-31"), YEAR_MONTH_DAY_FORMAT),
        base: 7.9,
        maxVariable: 0
    },
    {
        from: format(new Date("2024-08-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2024-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 8,
        maxVariable: 0
    },
    {
        from: format(new Date("2024-09-01"), YEAR_MONTH_DAY_FORMAT),
        to: format(new Date("2025-08-31"), YEAR_MONTH_DAY_FORMAT),
        base: 4.3,
        maxVariable: 3
    }
];
